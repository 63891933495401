import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import { NavLink } from 'cccisd-react-router';
import OrgReport from 'js/vendor/reports/reportTemplates/OrgAssessment';
import getDeploymentsQuery from './getDeployments.graphql';
import moment from 'moment';
import IconWarning from 'cccisd-icons/notification';
import {
    setReportDeploymentList,
    setReportDeploymentListLoaded,
    getSamhsaCompletion,
} from 'js/reducers/providerwellbeing.js';
import style from './style.css';
import Filter from './Filter';
import { DropdownList } from 'react-widgets';
import { toInteger } from 'lodash';

const Fortress = window.cccisd.fortress;
const appDefs = window.cccisd.appDefs;

const CurrentAssessment = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [deploymentId, setDeploymentId] = useState(1);
    const [deploymentCloseDate, setDeploymentCloseDate] = useState('');
    const [noOpenDeployments, setNoOpenDeployments] = useState(false);
    const [assessmentComplete, setAssessmentComplete] = useState(false);
    const [allRespondentsCompletedAssessment, setAllRespondentsCompletedAssessment] = useState(false);
    const [respondents, setRespondents] = useState([]);
    const [filter, setFilter] = useState({});
    const [allOrgAssessmentClosedDeployments, setAllOrgAssessmentClosedDeployments] = useState([]);
    const [selectedReportDeployment, setSelectedReportDeployment] = useState({ deploymentId: -1, label: '--' });
    const dispatch = useDispatch();
    // const samhsaModalRef = useRef();
    const reduxStore = useSelector(state => state.app.providerwellbeing);

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            const deploymentList = await getDeployments();

            // sorted by oldest deploymentId first (index 0) ; newest deploymentId last (index len-1)
            let sortedClosedDeploymentList = deploymentList
                .filter(val => {
                    if (isClosed(val.closesAt)) {
                        return true;
                    }
                    return false;
                })
                .sort((a, b) => {
                    let deploymentIdA = a.deploymentId;
                    let deploymentIdB = b.deploymentId;

                    if (deploymentIdA < deploymentIdB) {
                        return -1;
                    }
                    if (deploymentIdA > deploymentIdB) {
                        return 1;
                    }

                    return 0;
                });

            setAllOrgAssessmentClosedDeployments(sortedClosedDeploymentList);
            setSelectedReportDeployment(
                sortedClosedDeploymentList.length > 0
                    ? sortedClosedDeploymentList[sortedClosedDeploymentList.length - 1]
                    : { deploymentId: -1, label: '--' }
            );

            let respondentList = [];
            const openDeployments = deploymentList.filter(d => {
                const now = moment();
                let isOpen =
                    now.isBefore(undoTimezoneOffsetFieldValue(d.closesAt)) &&
                    now.isAfter(undoTimezoneOffsetFieldValue(d.opensAt));

                return isOpen;
            });
            dispatch(getSamhsaCompletion());
            dispatch(setReportDeploymentListLoaded(false));
            dispatch(
                setReportDeploymentList(
                    deploymentList.filter(
                        d =>
                            (d.respondentCount > 0 && d.assessmentStatus === 'Closed') ||
                            d.numCompleted >= d.respondentCount
                    )
                )
            );

            dispatch(setReportDeploymentListLoaded(true));

            if (openDeployments.length === 1) {
                setDeploymentId(deploymentList[0].deploymentId);
                respondentList = deploymentList[0].respondentList.map(r => r.pawnId);
                setDeploymentCloseDate(deploymentList[0].closesAt);
            }
            if (openDeployments.length === 0) {
                setNoOpenDeployments(true);
                if (deploymentList.length > 0) {
                    const mostRecentDeployment = Math.max(
                        ...deploymentList.map(val => {
                            return val.deploymentId;
                        })
                    );

                    setDeploymentId(mostRecentDeployment);
                }
            } else {
                const closesSoonestDeployment = openDeployments.reduce((prev, curr) =>
                    moment(prev.closesAt).isBefore(curr.closesAt) ? prev : curr
                );
                setDeploymentId(closesSoonestDeployment.deploymentId);
                respondentList = closesSoonestDeployment.respondentList.map(r => r.pawnId);
                setDeploymentCloseDate(closesSoonestDeployment.closesAt);
                setAssessmentComplete(closesSoonestDeployment.iCompleted > 0);
                setAllRespondentsCompletedAssessment(
                    closesSoonestDeployment.numCompleted >= closesSoonestDeployment.respondentCount
                );
            }
            setRespondents(respondentList);
            setIsLoading(false);
        })();
    }, []);

    function isClosed(endDate) {
        const now = moment();
        const result = now.isAfter(moment(undoTimezoneOffsetFieldValue(endDate)));
        return result;
    }

    const undoTimezoneOffsetFieldValue = date => {
        // Values are stored in UTC time need to convert to local timezone
        return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
    };

    const getReportSettings = () => {
        const reportList = appDefs.reports.list;

        const anyOrgReport = reportList.find(r => r.reportTemplateHandle === 'orgAssessment');
        if (anyOrgReport) {
            return anyOrgReport.reportTemplateSettings;
        }
    };

    const reportSettings = getReportSettings();

    const getDeployments = async () => {
        const result = await client.query({
            query: getDeploymentsQuery,
            fetchPolicy: 'network-only',
            variables: {
                assignmentHandle: 'orgAssessment',
                orgId: `orgId: ${Fortress.user.acting.group.id}`,
            },
        });
        const deploymentList = result.data.flows.assignment.deploymentList;
        return deploymentList;
    };

    if (isLoading) {
        return <Loader />;
    }

    const deploymentCloseDateUtc = moment.utc(deploymentCloseDate).toDate();
    if (
        (respondents.length > 0 && !respondents.includes(Fortress.user.acting.id)) ||
        (noOpenDeployments && reduxStore.reportDeploymentList.length < 1)
    ) {
        return (
            <div className="jumbotron" style={{ width: '900px', margin: '0 auto' }}>
                <h1>
                    <IconWarning />
                </h1>
                <p>
                    There is no open Organization Assessment right now. <br />
                    {/* If you’d like to assess your organization independently, see the Individual Assessment area. */}
                </p>
                <p>
                    {/* <NavLink to="/individualAssessment">
                        <button type="button" className="btn btn-primary btn-lg">
                            Go To Individual Assessment
                        </button>
                    </NavLink> */}
                </p>
            </div>
        );
    }

    const organizationInfo = window.cccisd.fortress.user.acting.group.type_data;
    const isOrgAdmin = window.cccisd.fortress.user.acting.role.handle === 'orgAdmin';

    const reportsList = allOrgAssessmentClosedDeployments.map((deploymentInfo, index) => {
        return `${index + 1}: ${deploymentInfo.label}`;
    });

    const reportView = isOrgAdmin ? (
        <>
            <label>Report Selection</label>
            <DropdownList
                className={style.reportDropdown}
                data={reportsList}
                label="Report Selection"
                defaultValue={
                    reportsList.length > 0
                        ? reportsList[reportsList.length - 1]
                        : 'No Assessments have been completed yet'
                }
                onChange={val => {
                    let allOrgAssessmentDeploClosedymentsIndex = toInteger(val.split(':')[0]) - 1;
                    setSelectedReportDeployment(
                        allOrgAssessmentClosedDeployments[allOrgAssessmentDeploClosedymentsIndex]
                    );
                }}
            />
            <br />
            <Filter filter={filter} setFilter={setFilter} />
            <OrgReport.player
                settings={reportSettings}
                deploymentId={selectedReportDeployment.deploymentId}
                filter={filter}
            />
        </>
    ) : (
        <div className="jumbotron" style={{ width: '900px', margin: '0 auto' }}>
            <h1>
                <IconWarning />
            </h1>
            <p>Assessment has been completed.</p>
        </div>
    );

    return (
        <div>
            {(assessmentComplete && allRespondentsCompletedAssessment) ||
            (noOpenDeployments && reduxStore.reportDeploymentList.length > 0) ? (
                reportView
            ) : (
                <>
                    {deploymentCloseDate && (
                        <div className={style.deploymentDeadline}>
                            <div className="alert alert-info" role="alert" style={{ margin: '0' }}>
                                Deadline to complete:{' '}
                                {moment(deploymentCloseDateUtc).local().format('dddd, MMMM Do YYYY, h:mm a')}
                            </div>
                        </div>
                    )}
                    <DeploymentPlayer
                        deploymentId={deploymentId}
                        pawnId={Fortress.user.acting.id}
                        pawnHash={Fortress.user.acting.random_hash}
                        flowProps={{
                            variables: {
                                isBHO: organizationInfo.isBHO ? 1 : 0,
                            },
                        }}
                        onComplete={() => {
                            setAssessmentComplete(true);
                            // if (!reduxStore.samhsaComplete) {
                            //     samhsaModalRef.current.open();
                            // }
                        }}
                    />
                    {/* <SamhsaSurveyModal /> */}
                </>
            )}
        </div>
    );
};

export default CurrentAssessment;
